<template>
	<div id="mapbox-container" :style="style"></div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
	props: {
		coords: {
			type: Array,
			default: () => []
		},
		style: {
			type: [Object, String],
			default: null
		},
		center: {
			type: Object,
			default: null
		},
		navPanel: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			initTime: 0,
			count: 0,
			isClick: false
		}
	},
	computed: {
		settings() {
			return {
				center: this.center
					? [this.center.lng, this.center.lat]
					: ['55.2707828', '25.2048493'],
				token: process.env.VUE_APP_MAPBOX_TOKEN,
				cluster_max_zoom: 18
			}
		},
		clusterCountConfig() {
			return {
				id: 'clusters',
				type: 'symbol',
				source: 'earthquakes',
        filter: ['has', 'point_count'],
				layout: {
					'icon-image': 'main-mapbox',
					'icon-offset': [0, 5],
					'text-field': '{point_count}',
					'text-font': ['Open Sans Regular', 'Arial Unicode MS Bold'],
					'text-size': 14,
					'icon-ignore-placement': true,
          'icon-size': 0.5
				},
			}
		},
    unclusteredPointConfig() {
      return {
        id: 'unclustered-point',
        type: 'symbol',
        source: 'earthquakes',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': 'main-mapbox',
          'icon-offset': [0, 5],
          'text-field': '1',
          'text-font': ['Open Sans Regular', 'Arial Unicode MS Bold'],
          'text-size': 14,
          'icon-ignore-placement': true,
          'icon-size': 0.5
        },
      }
    },
		clusterData() {
			if (!this.coords?.length) {
				return []
			}

			const bounds = new mapboxgl.LngLatBounds()
			const arr = this.coords?.map((itm) => {
				bounds.extend([itm.lng, itm.lat])

				return {
					type: 'Feature',
					properties: {
						id: itm.slug
					},
					geometry: { type: 'Point', coordinates: [itm.lng, itm.lat] }
				}
			})

			// Если у нас несколько точек, то подгоняем размеры карты под отображение точек
			if (this.coords?.length > 1) {
				this.map?.fitBounds(bounds, { padding: 50 })
			}

			return arr
		}
	},
	methods: {
		createMap() {
			try {
				mapboxgl.accessToken = this.settings.token

				this.map = new mapboxgl.Map({
					container: 'mapbox-container',
					style: 'mapbox://styles/mapbox/streets-v12',
					center: this.settings.center,
					zoom: 10,
          minZoom: 6,
          maxZoom: 17
				})

				// Disable rotate
				this.map.dragRotate.disable()
				this.map.touchZoomRotate.disableRotation()

				this.map.on('load', async () => {
					this.map.addSource('earthquakes', {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							crs: {
								type: 'name',
								properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' }
							},
							features: this.clusterData
						},
						cluster: true,
						clusterMaxZoom: this.settings.cluster_max_zoom,
						clusterRadius: 50,
						clusterProperties: {
							max: ['max', ['get', 'value']],
							min: ['min', ['get', 'value']],
						}
					})

					this.map.loadImage('/images/mapbox1.png', (err, image) => {
						if (err) return

						this.map.addImage('main-mapbox', image)
						this.map.addLayer(this.clusterCountConfig)
            this.map.addLayer(this.unclusteredPointConfig)

						// Когда кликаем по кластеру карта зумируется
						this.map.on('click', 'clusters', (e) => {
							const features = this.map.queryRenderedFeatures(e.point, {
								layers: ['clusters']
							})
							const clusterId = features[0].properties.cluster_id

							if (clusterId) {
								// Надо, чтобы понимать когда открывать модалку
								// Т. е. если мы кликнули, то выводим её
								this.isClick = true

								this.map
									.getSource('earthquakes')
									.getClusterExpansionZoom(clusterId, (err, zoom) => {
										if (err) return

										this.map.easeTo({
											center: features[0].geometry.coordinates,
											zoom: zoom
										})
									})
							} else {
								this.$emit('updateByClick', {
									slugs: features.map((itm) => itm.properties.id)
								})
							}
						})

						this.map.on('dragend', () => {
							const crds = this.map.getBounds()

							this.$emit('updateByDrag', {
								points: `${crds._sw.lat},${crds._sw.lng}~${crds._ne.lat},${crds._ne.lng}`
							})
						})

						this.map.on('zoomend', () => {
							const crds = this.map.getBounds()

							// TODO: переделать
							if (this.initTime > 0) {
								if (this.isClick) {
									this.$emit('updateByClick', {
										points: `${crds._sw.lat},${crds._sw.lng}~${crds._ne.lat},${crds._ne.lng}`
									})
								} else {
									this.$emit('updateByDrag', {
										points: `${crds._sw.lat},${crds._sw.lng}~${crds._ne.lat},${crds._ne.lng}`
									})
								}

								this.isClick = false
							}

							this.initTime += 1
						})

						this.map.on('mouseenter', 'clusters', () => {
							this.map.getCanvas().style.cursor = 'pointer'
						})
						this.map.on('mouseleave', 'clusters', () => {
							this.map.getCanvas().style.cursor = ''
						})
					})
				})
			} catch (e) {
				console.error('MapBox init error: ', e)
			}
		}
	},
	mounted() {
		if (!mapboxgl) {
			return
		}

		if (this.map) {
			return
		}

		this.createMap()
	}
}
</script>

<style lang="scss">
.mapboxgl-compact {
	visibility: hidden;
}
</style>
